<template>
	<div class="right-item py-2 px-4" v-on:dblclick.prevent="$parent.$parent.$emit('rightClicked', index)">{{ source.name }}</div>
</template>

<script>
export default {
	name: 'RightItem',
	props: {
		index: {
			type: Number,
		},
		source: {
			type: Object,
			default() {
				return {};
			},
		},
		selectedMenu: Object,
	},
};
</script>

<style scoped>
div.right-item {
	border-bottom: 1px solid lightgrey;
}
div.right-item:hover {
	background-color: #e5e5e5;
	cursor: pointer;
}
</style>
