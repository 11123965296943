<template>
	<div class="card card-custom">
		<div class="card-header border-0 py-5">
			<h3 class="card-title align-items-start flex-column">
				<span class="card-label font-weight-bolder text-dark">{{ $t('VIEWS.Administration.Right.title') }}</span>
			</h3>
			<div class="card-toolbar">
				<a href="" v-on:click.prevent="showRight('new')" class="btn btn-icon btn-light btn-sm mx-1" :class="!hasPermission('action.rights.add') ? 'disabled' : ''">
					<i class="fas fa-plus text-primary"></i>
				</a>
			</div>
		</div>
		<div class="card-body">
			<vue-loaders-square-spin v-if="pageProcesses.isLoading" color="#000"></vue-loaders-square-spin>
			<div class="row" v-if="!pageProcesses.isLoading">
				<div class="col-12 prinor-table">
					<b-table ref="textTable" :data="rights" :paginated="true" :per-page="5" default-sort="first_name" :default-sort-direction="'asc'" :sort-icon="'caret-up'">
						<b-table-column field="name" :label="$t('GENERAL.Forms.name')" v-slot="props" :sortable="true" :searchable="true" :cell-class="'d-grid text-valign'">
							<template>
								<span class="truncate" v-if="isEditable(props.row)">{{ props.row.name }}</span>
								<span class="truncate" v-if="!isEditable(props.row)">{{ $t('GENERAL.General.standard') + ': ' + props.row.name }}</span>
							</template>
						</b-table-column>
						<b-table-column field="description" :label="$t('GENERAL.Forms.description')" v-slot="props" :sortable="true" :searchable="true" :cell-class="'d-grid text-valign'">
							<template>
								<span class="truncate">{{ props.row.description }}</span>
							</template>
						</b-table-column>
						<b-table-column field="action" :label="$t('GENERAL.General.actions')" v-slot="props" :sortable="false" :searchable="false" :cell-class="'d-grid'">
							<template>
								<a href="" class="btn btn-icon btn-light btn-sm mx-1" v-on:click.prevent="showRight(props.row.id)" :class="!isEditable(props.row) || !hasPermission('action.rights.edit') ? 'disabled' : ''">
									<i class="fas fa-edit text-primary"></i>
								</a>
								<a href="" class="btn btn-icon btn-light btn-sm mx-1" v-on:click.prevent="onDelete(props.row.id)" :class="!isEditable(props.row) || !hasPermission('action.rights.delete') ? 'disabled' : ''">
									<i class="far fa-trash-alt text-primary"></i>
								</a>
							</template>
						</b-table-column>
					</b-table>
				</div>
			</div>
		</div>
		<b-modal
			ref="edit-modal"
			content-class="modal-border"
			size="xl"
			:title="$t('VIEWS.Administration.Right.Edit.title') + ': ' + selectedRight.name"
			:cancel-title="$t('GENERAL.Buttons.cancel')"
			:ok-title="$t('GENERAL.Forms.save')"
			v-on:ok="onUpdate"
			:on-cancel="resetDialog"
			scrollable
		>
			<b-tabs content-class="mt-3" justified>
				<b-tab :title="$t('VIEWS.Administration.Right.Edit.General.title')" active>
					<b-form-group :label="$t('GENERAL.Forms.name')">
						<b-form-input type="text" v-model="selectedRight.name"></b-form-input>
					</b-form-group>

					<b-form-group :label="$t('GENERAL.Forms.description')">
						<b-form-textarea rows="5" v-model="selectedRight.description"></b-form-textarea>
					</b-form-group>
				</b-tab>
				<b-tab :title="$t('VIEWS.Administration.Right.Edit.Menu.title')">
					<div class="row">
						<div class="col-5">
							<h5 class="mb-3 ml-3">{{ $t('VIEWS.Administration.Right.Edit.deactivated') }}</h5>
						</div>
						<div class="col-2"></div>
						<div class="col-5">
							<h5 class="mb-3 ml-3">{{ $t('VIEWS.Administration.Right.Edit.activated') }}</h5>
						</div>
					</div>
					<div class="row">
						<div class="col-5">
							<virtual-list class="rights-list" style="height: 360px; overflow-y: auto;" :data-key="'id'" :data-sources="menuRights" :data-component="rightItemComponent" @rightClicked="addMenuRight" />
							<!--							<draggable class="list-group" tag="div" v-model="menuRights" group="permissions_menu">-->
							<!--								<div class="list-group-item" v-for="permission in menuRights" :key="permission.id">{{ permission.name }}</div>-->
							<!--							</draggable>-->
						</div>
						<div class="col-2 text-center">
<!--							<div class="d-block my-2">-->
<!--								<a href="" class="btn btn-icon btn-light btn-sm mx-1">-->
<!--									<i class="fas fa-angle-left text-primary"></i>-->
<!--								</a>-->
<!--							</div>-->
							<div class="d-block my-2">
								<a href="" v-on:click.prevent="removeAllMenu" class="btn btn-icon btn-light btn-sm mx-1">
									<i class="fas fa-angle-double-left text-primary"></i>
								</a>
							</div>
							<div class="d-block my-2">
								<a href="" v-on:click.prevent="addAllMenu" class="btn btn-icon btn-light btn-sm mx-1">
									<i class="fas fa-angle-double-right text-primary"></i>
								</a>
							</div>
<!--							<div class="d-block my-2">-->
<!--								<a href="" class="btn btn-icon btn-light btn-sm mx-1">-->
<!--									<i class="fas fa-angle-right text-primary"></i>-->
<!--								</a>-->
<!--							</div>-->
						</div>
						<div class="col-5">
							<virtual-list class="rights-list" style="height: 360px; overflow-y: auto;" :data-key="'id'" :data-sources="selectedMenuRights" :data-component="rightItemComponent" @rightClicked="removeMenuRight" />
						</div>
					</div>
				</b-tab>
				<b-tab :title="$t('VIEWS.Administration.Right.Edit.Action.title')">
					<div class="row">
						<div class="col-5">
							<h5 class="mb-3 ml-3">{{ $t('VIEWS.Administration.Right.Edit.deactivated') }}</h5>
						</div>
						<div class="col-2"></div>
						<div class="col-5">
							<h5 class="mb-3 ml-3">{{ $t('VIEWS.Administration.Right.Edit.activated') }}</h5>
						</div>
					</div>
					<div class="row">
						<div class="col-5">
							<virtual-list class="rights-list" style="height: 360px; overflow-y: auto;" :data-key="'id'" :data-sources="permissionRights" :data-component="rightItemComponent" @rightClicked="addActionRight" />
						</div>
						<div class="col-2 text-center">
<!--							<div class="d-block my-2">-->
<!--								<a href="" class="btn btn-icon btn-light btn-sm mx-1">-->
<!--									<i class="fas fa-angle-left text-primary"></i>-->
<!--								</a>-->
<!--							</div>-->
							<div class="d-block my-2">
								<a href="" v-on:click.prevent="removeAllAction" class="btn btn-icon btn-light btn-sm mx-1">
									<i class="fas fa-angle-double-left text-primary"></i>
								</a>
							</div>
							<div class="d-block my-2">
								<a href="" v-on:click.prevent="addAllAction" class="btn btn-icon btn-light btn-sm mx-1">
									<i class="fas fa-angle-double-right text-primary"></i>
								</a>
							</div>
<!--							<div class="d-block my-2">-->
<!--								<a href="" class="btn btn-icon btn-light btn-sm mx-1">-->
<!--									<i class="fas fa-angle-right text-primary"></i>-->
<!--								</a>-->
<!--							</div>-->
						</div>
						<div class="col-5">
							<virtual-list class="rights-list" style="height: 360px; overflow-y: auto;" :data-key="'id'" :data-sources="selectedPermissionRights" :data-component="rightItemComponent" @rightClicked="removeActionRight" />
						</div>
					</div>
				</b-tab>
			</b-tabs>
		</b-modal>
	</div>
</template>

<script>
import Swal from 'sweetalert2';
import ApiService from '@/core/services/api.service';
import { PRINOR_TOASTS } from '@/core/services/toast.service';
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module';
import { PRINOR_PERMISSION } from '@/core/services/permissions.service';
import { mapGetters } from 'vuex';
import { PRINOR_PROCESSES } from '@/core/services/actions.service';
import { SET_ACTION } from '@/core/services/store/actions.module';
import RightItem from '@/view/component/administration/RightItem';

export default {
	name: 'Rights',
	data() {
		return {
			rightItemComponent: RightItem,
			rights: [],
			permissions: [],
			selectedRight: {
				id: '',
				name: '',
				description: '',
				organization_id: '',
				permissions: [],
			},
		};
	},
	mounted() {
		this.$store.dispatch(SET_ACTION, []);
		this.$store.dispatch(SET_BREADCRUMB, [
			{
				title: this.$t('MENU.Administration.Administration'),
				route: '/administration',
			},
			{ title: this.$t('MENU.Administration.Users') },
		]);

		PRINOR_PROCESSES.isLoading();
		this.onGet();
	},
	computed: {
		...mapGetters(['pageProcesses']),
		menuRights: {
			get() {
				let filtered = this.permissions
					.filter(value => {
						return value.group === 'Menu';
					})
					.filter(value => {
						return !this.selectedRight.permissions.some(p => {
							return value.id === p.id;
						});
					});

				filtered.forEach(right => {
					right.name = this.$t('RIGHT.' + right.key);
				});

				filtered.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));

				if (filtered.length > 0) {
					return filtered;
				} else {
					return [];
				}
			},
			set() {},
		},
		selectedMenuRights: function() {
			let filtered = this.selectedRight.permissions.filter(value => {
				return value.group === 'Menu';
			});

			filtered.forEach(right => {
				right.name = this.$t('RIGHT.' + right.key);
			});

			if (filtered.length > 0) {
				return filtered;
			} else {
				return [];
			}
		},
		permissionRights: {
			get() {
				let filtered = this.permissions
					.filter(value => {
						return value.group !== 'Menu';
					})
					.filter(value => {
						return !this.selectedRight.permissions.some(p => {
							return value.id === p.id;
						});
					});

				filtered.forEach(right => {
					right.name = this.$t('RIGHT.' + right.key);
				});

				filtered.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));

				if (filtered.length > 0) {
					return filtered;
				} else {
					return [];
				}
			},
			set() {},
		},
		selectedPermissionRights: function() {
			let filtered = this.selectedRight.permissions.filter(value => {
				return value.group !== 'Menu';
			});

			filtered.forEach(right => {
				right.name = this.$t('RIGHT.' + right.key);
			});

			if (filtered.length > 0) {
				return filtered;
			} else {
				return [];
			}
		},
	},
	methods: {
		onGet: function() {
			PRINOR_PROCESSES.isLoading();
			this.get().then(data => {
				this.rights = data.data.rights;
				this.permissions = data.data.permissions;
			});
		},
		isEditable: function(item) {
			return !(item.organization_id === null || item.organization_id === '');
		},
		addAllMenu: function() {
			let count = this.menuRights.length;
			for (let i = 0; i < count; i++) {
				this.addMenuRight(0);
			}
		},
		removeAllMenu: function() {
			let count = this.selectedMenuRights.length;
			for (let i = 0; i < count; i++) {
				this.removeMenuRight(0);
			}
		},
		addMenuRight: function(index) {
			this.selectedRight.permissions.push(this.menuRights[index]);
		},
		removeMenuRight: function(index) {
			let item = this.selectedMenuRights[index];
			let foundIndex = -1;
			let i = 0;

			this.selectedRight.permissions.forEach(permission => {
				if (permission.id === item.id) {
					foundIndex = i;
				}
				i++;
			});

			this.selectedRight.permissions.splice(foundIndex, 1);
		},
		addAllAction: function() {
			let count = this.permissionRights.length;
			for (let i = 0; i < count; i++) {
				this.addActionRight(0);
			}
		},
		removeAllAction: function() {
			let count = this.selectedPermissionRights.length;
			for (let i = 0; i < count; i++) {
				this.removeActionRight(0);
			}
		},
		addActionRight: function(index) {
			this.selectedRight.permissions.push(this.permissionRights[index]);
		},
		removeActionRight: function(index) {
			let item = this.selectedPermissionRights[index];
			let foundIndex = -1;
			let i = 0;

			this.selectedRight.permissions.forEach(permission => {
				if (permission.id === item.id) {
					foundIndex = i;
				}
				i++;
			});

			this.selectedRight.permissions.splice(foundIndex, 1);
		},
		hasPermission(name) {
			return PRINOR_PERMISSION.action(name);
		},
		getRights: function(type) {
			if (type === 'menu') {
				return this.selectedRight.permissions.filter(value => {
					return value.group === 'Menu';
				});
			} else {
				return this.selectedRight.permissions.filter(value => {
					return value.group !== 'Menu';
				});
			}
		},
		showRight(id) {
			if (id !== 'new') {
				let filtered = this.rights.filter(value => {
					return value.id === id;
				});
				if (filtered.length === 1) {
					this.selectedRight = filtered[0];
					this.$refs['edit-modal'].show();
				}
			} else {
				this.selectedRight.id = 'new';
				this.$refs['edit-modal'].show();
			}
		},
		resetDialog: function() {
			this.selectedRight = {
				name: '',
			};
		},
		onUpdate() {
			PRINOR_PROCESSES.isUpdating();
			this.update().then(() => {
				this.onGet();
			});
		},
		onDelete(id) {
			let that = this;

			Swal.fire({
				title: this.$t('VIEWS.Administration.Right.deleteTitle'),
				text: this.$t('VIEWS.Administration.Right.deleteConfirmation'),
				icon: 'warning',
				confirmButtonText: this.$t('GENERAL.Forms.delete'),
				showCancelButton: true,
				cancelButtonText: this.$t('GENERAL.Buttons.cancel'),
				customClass: {
					confirmButton: 'btn btn-danger',
					cancelButton: 'btn btn-secondary',
				},
				heightAuto: false,
				allowOutsideClick: false,
				allowEscapeKey: false,
				allowEnterKey: false,
			}).then(result => {
				if (result.isConfirmed) {
					that.delete(id).then(data => {
						if (data.success === true) {
							this.onGet();
						}
					});
				}
			});
		},
		get() {
			return new Promise(resolve => {
				ApiService.setHeader();
				ApiService.get('rights').then(data => {
					PRINOR_PROCESSES.reset();
					if (ApiService.checkSuccess(data)) {
						resolve(data.data);
					}
				});
			});
		},
		update() {
			return new Promise(resolve => {
				ApiService.setHeader();
				ApiService.put('rights/' + this.selectedRight.id, this.selectedRight).then(data => {
					PRINOR_PROCESSES.reset();
					if (ApiService.checkSuccess(data)) {
						if (data.data.success === true) {
							PRINOR_TOASTS.save.confirmation(this);
						} else {
							PRINOR_TOASTS.save.error(this, data.data.error);
						}

						resolve(data.data);
					}
				});
			});
		},
		delete(id) {
			return new Promise(resolve => {
				ApiService.setHeader();
				ApiService.delete('rights/' + id).then(data => {
					PRINOR_PROCESSES.reset();
					if (ApiService.checkSuccess(data)) {
						resolve(data.data);
					}
					else {
						if (data.data != null && data.data.data != null) {
							let text = this.$t('VIEWS.Administration.Right.deleteUsersText') + "";
							data.data.data.forEach(user => {
								text += "<br/>" + user.first_name + " " + user.last_name;
							});

							Swal.fire({
								title: this.$t('VIEWS.Administration.Right.deleteError'),
								html: text,
								icon: 'error',
								confirmButtonText: this.$t('GENERAL.General.okay'),
								customClass: {
									confirmButton: 'btn btn-danger',
									cancelButton: 'btn btn-secondary',
								},
								heightAuto: false,
								allowOutsideClick: false,
								allowEscapeKey: false,
								allowEnterKey: false,
							});
						}
						else {
							PRINOR_TOASTS.general.error(this, data.data.error);
							resolve(data.data);
						}
					}

				});
			});
		},
	},
};
</script>

<style scoped>
.text-valign {
	vertical-align: middle !important;
}
.rights-list {
	border: 1px solid lightgrey;
	border-radius: 5px;
}
</style>
